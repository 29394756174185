<template>
  <div>
    <h2 v-html="$t('search_segmentation_title')"></h2>
    <!-- Segment -->
    <v-select
      id="search_segmentation_segmentation_type"
      v-model="searchCardStore.segmentationType"
      :label="$t('search_segmentation_segmentation_type')"
      :items="segmentationTypes"
      :item-title="this.$getLangKey()"
      item-value="name"
      clearable
      @keyup.enter="search"
    />
    <!-- Fütterung -->
    <v-select
      id="search_segmentation_feeding"
      v-model="searchCardStore.feeding"
      :label="$t('search_segmentation_feeding')"
      :items="feedings"
      :item-title="this.$getLangKey()"
      item-value="name"
      clearable
      @keyup.enter="search"
    />
    <!-- Abschlussmonat von -->
    <DbmMonthPicker
      name="search_segmentation_valid_from"
      id="search_segmentation_valid_from"
      v-model="searchCardStore.validFrom"
      :dateLabel="$t('search_segmentation_valid_from')"
      :clearable="true"
    />
    <!-- Abschlussmonat bis -->
    <DbmMonthPicker
      name="search_segmentation_valid_until"
      id="search_segmentation_valid_until"
      v-model="searchCardStore.validUntil"
      :dateLabel="$t('search_segmentation_valid_until')"
      :clearable="true"
      returnType="lastOfMonth"
    />
    <!-- Status -->
    <v-select
      id="search_segmentation_status"
      v-model="searchCardStore.status"
      :label="$t('search_segmentation_status')"
      :items="segmentationStatusesIncVirtual"
      :item-title="this.$getLangKey()"
      item-value="name"
      clearable
      @keyup.enter="search"
    />
    <!-- Meldepflicht BOM -->
    <v-select
      id="search_segmentation_reporting_obligation"
      v-model="searchCardStore.reportingObligation"
      :label="$t('search_segmentation_reporting_obligation')"
      :items="reportingObligations"
      :item-title="this.$getLangKey()"
      item-value="name"
      clearable
      @keyup.enter="search"
    />

    <!-- Betrag Differenz [kg] > -->
    <v-text-field
      id="search_segmentation_difference"
      v-model="searchCardStore.difference"
      :label="$t('search_segmentation_difference')"
      type="text"
      @keyup.enter="search"
    />

    <div class="link align-right" @click="expanded = !expanded">
      <span v-if="!expanded" v-html="$t('more')"></span>
      <span v-else v-html="$t('less')"></span>
    </div>
    <div v-if="expanded">
      <!-- expanded here -->
      <!-- Pos-Nr. -->
      <v-text-field
        id="search_segmentation_pos_nr"
        :rules="[$rules.isNumeric]"
        v-model="searchCardStore.posNr"
        :label="$t('search_segmentation_pos_nr')"
        type="text"
        @keyup.enter="search"
      />
      <!-- MBH-ID Ablieferer -->
      <v-text-field
        name="search_segmentation_dairy_out_ident"
        :label="$t('search_segmentation_dairy_out_ident')"
        v-model="searchCardStore.dairyOutIdent"
        @keyup.enter="search"
      ></v-text-field>
      <!-- MBH-ID Zukäufer -->
      <v-text-field
        name="search_segmentation_dairy_in_ident"
        :label="$t('search_segmentation_dairy_in_ident')"
        v-model="searchCardStore.dairyInIdent"
        @keyup.enter="search"
      ></v-text-field>

      <!-- Nur Abgrenzungs-Meldungen (54800) -->
      <v-select
        id="search_segmentation_delimitation_only"
        v-model="searchCardStore.delimitationOnly"
        :label="$t('search_segmentation_delimitation_only')"
        :items="delimitation_only"
        item-title="text"
        item-value="value"
        clearable
        @keyup.enter="search"
      />
      <!-- Zuletzt bearbeitet von -->
      <DbmDatePicker v-model="searchCardStore.modificationFrom" :dateLabel="$t('search_segmentation_modification_from')" :clearable="true" />
      <!-- Zuletzt bearbeitet bis -->
      <DbmDatePicker v-model="searchCardStore.modificationUntil" :dateLabel="$t('search_segmentation_modification_until')" :clearable="true" />
      <!-- Korrektur -->
      <v-select
        id="search_segmentation_is_correction"
        v-model="searchCardStore.correction"
        :label="$t('search_segmentation_correction')"
        :items="segmentation_is_correction"
        item-title="text"
        item-value="value"
        clearable
        @keyup.enter="search"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters, mapActions } from 'vuex'
import baseSearchVue from './baseSearch.vue'
import DbmMonthPicker from '@/components/dbmMonthPicker.vue'

import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { useSearchSegmentationStore } from '@/store/SearchSegmentationStore'
import { useFeedingsStore } from '@/store/enums/FeedingsStore'
import { useSegmentationStatusStore } from '@/store/enums/SegmentationStatusStore'
import { useReportingObligationsStore } from '@/store/enums/ReportingObligationsStore'
import { useSegmentationTypesStore } from '@/store/enums/SegmentationTypesStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchSegmentation',
  extends: baseSearchVue,
  data() {
    return {
      expanded: false,
      menu1: false,
      menu2: false,
      delimitation_only: [
        { value: 0, text: this.$t('search_segmentation_delimitation_no') },
        { value: 1, text: this.$t('search_segmentation_delimitation_yes') }
      ],
      segmentation_is_correction: [
        { value: 1, text: this.$t('search_segmentation_correction_yes') },
        { value: 0, text: this.$t('search_segmentation_correction_no') }
      ]
    }
  },
  computed: {
    segmentationStatusesIncVirtual() {
      return useSegmentationStatusStore().segmentationStatusesIncVirtual
    },
    segmentationTypes() {
      return useSegmentationTypesStore().segmentationTypes
    },
    feedings() {
      return useFeedingsStore().feedings
    },
    reportingObligations() {
      return useReportingObligationsStore().reportingObligations
    }
  },
  components: {
    DbmMonthPicker: DbmMonthPicker,
    DbmDatePicker: DbmDatePicker
  },
  beforeMount() {
    this.setSearchCardStore(useSearchSegmentationStore(), true)
    useFeedingsStore().fetchFeedings()
    useSegmentationStatusStore().fetchSegmentationStatusesIncVirtual()
    useReportingObligationsStore().fetchReportingObligations()
  }
})
</script>
